<script setup lang="ts">
const { to, fallback, text, icon, replace } = defineProps({
  to: {
    type: [String, Object] as PropType<string | Record<string, string> | null>,
    required: false,
    default: null,
  },
  fallback: {
    type: String,
    required: false,
    default: null,
  },
  text: {
    type: String,
    required: false,
    default: undefined,
  },
  icon: {
    type: String,
    required: false,
    default: 'i-ph-arrow-left-bold',
  },
  replace: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();
const spellStore = useSpellsStore();
const { inEditor } = useRouteName();

const handleBack = async () => {
  if (inEditor.value) {
    spellStore.trackUnfinishedSpell();
  }

  if (to) {
    await navigateTo(to, { replace });
  } else if (window.history.length <= 1) {
    await navigateTo(fallback, { replace });
  } else {
    await router.back();
  }
};
</script>
<template>
  <UButton
    :icon="icon"
    size="lg"
    color="gray"
    :label="text"
    square
    variant="ghost"
    class="text-lg"
    @click="handleBack"
  />
</template>
